<template>
  <div class="mt-4">
    <button
      class="bg-secondary font-dm_sans text-md text-white rounded-xl border-2 border-transparent px-4 py-2.5 text-center inline-flex items-center"
      @click="genPDF"
    >
      <span>Download certificate</span>
    </button>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
export default {
  methods: {
    genPDF() {
      var doc = new jsPDF({
        orientation: "l",
        format: "a4",
      });
      doc.addImage("../../bg_certificate.jpg", "jpeg", 0, 0, 297, 210);
      autoTable(doc, {
        startY: 69,
        margin: {
          left: 21,
        },
        body: [
          [
            {
              content: "Name : " + this.$store.state.fileName,
              styles: {
                halign: "left",
                fontSize: 12,
                textColor: "#000000",
              },
            },
          ],
        ],
        theme: "plain",
        styles: {},
      });
      autoTable(doc, {
        startY: 77,
        margin: {
          left: 21,
        },
        body: [
          [
            {
              content: "Hash : " + this.$store.state.hashFile,
              styles: {
                halign: "left",
                fontSize: 12,
                charSpace: 0,
                textColor: "#000000",
              },
            },
          ],
        ],
        theme: "plain",
        styles: {},
      });
      autoTable(doc, {
        startY: 112,
        margin: {
          left: 21,
        },
        body: [
          [
            {
              content: "Owner : " + this.$store.state.accountAddr,
              styles: {
                halign: "left",
                fontSize: 12,
                textColor: "#000000",
              },
            },
          ],
        ],
        theme: "plain",
        styles: {},
      });
      autoTable(doc, {
        startY: 156,
        margin: {
          left: 21,
        },
        body: [
          [
            {
              content: "Tx : " + this.$parent.txhash,
              styles: {
                halign: "left",
                fontSize: 12,
                textColor: "#000000",
              },
            },
          ],
        ],
        theme: "plain",
        styles: {},
      });
      doc.save("Patent_certificate" + ".pdf");
    },
  },
};
</script>

<style></style>
