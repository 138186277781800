<template>
  <div class="patent">
    <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35 pt-20">
      PATENT YOUR
      <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
        >CREATIONS</span
      >
    </h1>

    <div
      v-if="txhash === null"
      class="bg-third w-max mx-auto mt-8 rounded-xl shadow-lg relative"
    >
      <!-- Loading layout -->
      <div
        v-if="getHashStatus"
        class="absolute z-10 w-full h-full flex items-center justify-center"
      >
        <div>
          <svg
            aria-hidden="true"
            class="mr-2 w-6 h-6 text-gray-300 animate-spin fill-secondary"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
        <h1 class="font-dm_400 text-2xl">Hash generation</h1>
      </div>
      <!-- Wrong file name layout-->
      <div
        v-if="iswrongname"
        class="absolute z-10 w-full h-full flex flex-col items-center justify-center space-y-4"
      >
        <h1 class="font-dm_400 text-2xl">Bad character</h1>
        <h2 class="font-dm_400 text-xl">Show FAQ for more details</h2>
        <button
          @click="this.iswrongname = null"
          class="bg-secondary border-2 border-transparent hover:border-primary font-dm_sans rounded-xl text-md text-white px-10 py-2.5 text-center inline-flex items-center"
        >
          Close
        </button>
      </div>
      <!-- Wrong network layout -->
      <div
        v-if="iswrongnetwork"
        class="absolute z-10 w-full h-full flex flex-col items-center justify-center space-y-4"
      >
        <h1 class="font-dm_400 text-2xl">Wrong Network</h1>
        <button
          @click="this.$refs.txbutton.switchNetwork"
          class="bg-secondary border-2 border-transparent hover:border-primary font-dm_sans rounded-xl text-md text-white px-10 py-2.5 text-center inline-flex items-center"
        >
          Switch Network
        </button>
      </div>
      <!-- Main -->
      <div
        class="p-3"
        :class="
          getHashStatus || iswrongnetwork || iswrongname
            ? 'blur-lg'
            : 'blur-none'
        "
      >
        <ImportButton ref="importbutton" />
        <HashFile ref="hashfile" />
        <TxButton ref="txbutton" />
      </div>
    </div>
    <div
      v-else
      class="bg-third w-max mx-auto mt-8 rounded-xl shadow-lg relative"
    >
      <div class="p-3">
        <div
          class="bg-primary w-64 h-10 rounded-lg flex items-center justify-center mx-auto relative"
        >
          <span
            class="text-secondary text-2xl"
            :class="isCopied ? 'blur-md' : 'blur-none'"
            @click="copied"
          >
            {{ getComputedTxHash }}</span
          >
          <span
            v-if="isCopied === true"
            class="text-secondary text-xl absolute z-10 w-full h-full flex items-center justify-center"
          >
            Copied
          </span>
        </div>
        <ExportPDF />
        <div
          class="bg-primary w-64 h-10 rounded-lg flex items-center justify-center mt-4 mx-auto"
        >
          <button
            class="bg-primary font-dm_sans text-md text-secondary"
            @click="clear"
          >
            Patent new idea
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImportButton from "@/components/ImportButton.vue";
import HashFile from "@/components/HashFile.vue";
import TxButton from "@/components/SendTxButton.vue";
import ExportPDF from "@/components/ExportPDF.vue";
export default {
  name: "PatentView",
  components: {
    ImportButton,
    HashFile,
    TxButton,
    ExportPDF,
  },
  data() {
    return {
      iswrongname: null,
      iswrongnetwork: null,
      txhash: null,
      isCopied: false,
    };
  },
  computed: {
    getHashStatus() {
      return !!this.$store.state.fileName ^ !!this.$store.state.hashFile;
    },
    getComputedTxHash() {
      return (
        this.txhash.substring(0, 8) +
        "..." +
        this.txhash.substring(this.txhash.length - 8, this.txhash.length)
      );
    },
  },
  methods: {
    copied() {
      navigator.clipboard.writeText(this.txhash);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    },
    clear() {
      this.txhash = null;
      this.$store.state.fileName = null;
      this.$store.state.hashFile = null;
    },
  },
};
</script>
