<template>
  <div class="bg-primary flex flex-col min-h-screen">
    <NavBar class="sticky top-0 z-50" />
    <router-view class="flex-grow" />
    <FooterComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    FooterComponent,
  },
};
</script>

<style>
#app {
  font-family: DM Sans, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
  background-color: #ff6501;
}
</style>
