<template>
  <div>
    <div class="xl:mx-48 py-15">
      <h1
        class="font-dm_sans max-sm:text-4xl max-md:text-5xl md:text-6xl text-[#0c1d37] xl:mx-35 pt-40"
      >
        PROTECT YOUR CREATIONS AGAINST THEFT WITH THE
        <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
          >BLOCKCHAIN</span
        >
      </h1>
      <p class="font-dm_400 text-[#0c1d37] text-lg xl:mx-35 mx-20 pt-10">
        Patentidea is a solution that allows you to time-stamp your creations in
        the blockchain which is an unfalsifiable technology and to assert your
        rights in case of infringement or unfounded accusations.
      </p>
      <MetaMask class="mt-20 lg:hidden" />
    </div>
    <div class="mt-16 mb-16">
      <router-link
        to="/patent"
        id="link"
        class="bg-secondary text-white border-2 font-dm_sans rounded-full text-md px-5 py-2.5 text-center inline-flex items-center"
      >
        Protect your creations
      </router-link>
    </div>
    <div
      class="flex max-md:flex-col max-md:items-center md:justify-evenly pt-6"
    >
      <div class="w-96">
        <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35">
          What is
          <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
            >blockchain</span
          >
          <span> ?</span>
        </h1>
        <p>
          Blockchain is a digital ledger of transactions that are shared across
          multiple computers so that the record cannot be tampered with. Widely
          used for digital currencies such as Bitcoin and Ethereum, it also
          allows to store information that will be time-stamped and registered
          in an immutable way.
        </p>
      </div>
      <div class="w-96 max-md:mt-20">
        <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35">
          What's a
          <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
            >hash</span
          >
          <span> ?</span>
        </h1>
        <p>
          A hash is the result of a hash function that takes as input a series
          of bytes and returns a hash of a fixed size that is unique to the
          input data. At PatentIdea we use this function to obtain a hash that
          will be unique to the document you provide us.
        </p>
      </div>
    </div>
    <div class="xl:w-1/3 max-xl:w-96 mx-auto mt-20">
      <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35">
        How does it
        <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
          >work</span
        >
        <span> ?</span>
      </h1>
      <p>
        At Patentidea we use the immutability of the blockchain and the
        uniqueness of hashing technologies to authenticate your work. You just
        have to provide us with a document in the format you want and approve
        the transaction on your wallet and we provide you with an authentication
        certificate that will guarantee that the unique signature of your
        document has been recorded on the blockchain.
      </p>
    </div>
    <div>
      <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35 pt-20">
        How to go
        <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
          >about it</span
        >
        <span> ?</span>
      </h1>
      <div
        class="flex max-xl:flex-col xl:justify-around max-xl:space-y-6 max-xl:items-center max-xl:mt-8 xl:mt-12 xl:mx-36"
      >
        <div>
          <div
            class="w-8 h-8 bg-white border-2 border-secondary rounded-md mx-auto"
          >
            <span class="font-dm_sans text-xl">1</span>
          </div>
          <div
            class="bg-secondary border-2 rounded-xl px-10 py-2.5 inline-flex my-2"
          >
            <span class="font-dm_sans text-md text-white">
              Upload your file
            </span>
          </div>
          <span class="block font-dm_400 text-xl mb-2"
            >Import a file in any <br />format</span
          >
        </div>
        <!-- Div 2 -->
        <div>
          <div
            class="w-8 h-8 bg-white border-2 border-secondary rounded-md mx-auto"
          >
            <span class="font-dm_sans text-xl">2</span>
          </div>
          <div
            class="bg-third border-2 rounded-xl border-secondary px-4 py-1 inline-flex my-2"
          >
            <span class="text-secondary text-2xl">9d5716...50ca41</span>
          </div>
          <span class="block font-dm_400 text-xl mb-2"
            >We generate a hash that <br />will be unique to your file
          </span>
        </div>
        <!-- End Div 2 -->
        <div>
          <div
            class="w-8 h-8 bg-white border-2 border-secondary rounded-md mx-auto"
          >
            <span class="font-dm_sans text-xl">3</span>
          </div>
          <div
            class="bg-secondary border-2 rounded-xl px-10 py-2.5 inline-flex my-2"
          >
            <span class="font-dm_sans text-md text-white"> Save on chain </span>
          </div>
          <span class="block font-dm_400 text-xl mb-2"
            >Approve the transaction <br />on your wallet</span
          >
        </div>
        <!-- Div 3 -->
        <div>
          <div
            class="w-8 h-8 bg-white border-2 border-secondary rounded-md mx-auto"
          >
            <span class="font-dm_sans text-xl">4</span>
          </div>
          <div
            class="bg-secondary border-2 rounded-xl px-10 py-2.5 inline-flex my-2"
          >
            <span class="font-dm_sans text-md text-white">
              Download certificate
            </span>
          </div>
          <span class="block font-dm_400 text-xl mb-2"
            >Get your certificate which <br />attests that your work is
            <br />deposited on the blockchain</span
          >
        </div>
        <!-- End Div 3 -->
      </div>
    </div>
    <div
      class="flex max-md:flex-col max-md:items-center md:justify-evenly pt-6 mt-20"
    >
      <div class="xl:w-1/4 max-xl:w-96">
        <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35">
          About the
          <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
            >certificate</span
          >
        </h1>
        <p>
          Your certificate authenticates that you have recorded the unique
          signature of your document on the blockchain. We indicate several
          pieces of information, the most important of which is the address of
          the transaction that will allow you to view the hash of your document
          via a block explorer.
        </p>
        <br />
        <p>
          Please note that it is imperative that you keep the document you
          provided with your certificate without making any changes to it.
          Otherwise, the hash of your document will be different and will not
          correspond to the one registered on the blockchain.
        </p>

        <p />
      </div>
      <div class="xl:w-1/4 max-xl:w-96 max-2xl:pt-20">
        <img
          class="border-2 border-secondary"
          src="../assets/demo_certificate.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MetaMask from "@/components/MetaMask.vue";
export default {
  name: "NavView",
  components: {
    MetaMask,
  },
};
</script>
