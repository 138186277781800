<template>
  <div>
    <div class="mt-4">
      <div
        class="bg-primary w-64 h-10 rounded-lg flex items-center justify-center mx-auto"
      >
        <span v-if="this.hash !== null" class="text-secondary text-2xl">
          {{ computed_hash }}</span
        >
        <span v-else class="text-gray-500 text-sm"> File hash </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HashFile",
  data() {
    return {
      hash: this.$store.state.hashFile,
    };
  },
  computed: {
    computed_hash() {
      return (
        this.hash.substring(0, 6) +
        "..." +
        this.hash.substring(this.hash.length - 6, this.hash.length)
      );
    },
  },
  methods: {
    onFilePicked(e) {
      let _this = this;

      function on_hash_done(hash, time_elapsed_seconds) {
        console.log("Done" + time_elapsed_seconds + "Hash :" + hash);
        _this.hash = hash;
        _this.$store.commit("setHashFile", hash);
      }

      function on_hash_progress(progress, time_elapsed_seconds) {
        console.log(
          Math.round(progress * 1000) / 10 + "% " + time_elapsed_seconds + "s"
        );
      }

      function on_hash_error(error) {
        console.log("Error : " + error);
      }

      function toHexString(byteArray) {
        return Array.from(byteArray, function (byte) {
          return ("0" + (byte & 0xff).toString(16)).slice(-2);
        }).join("");
      }

      function hash_file(file, on_hash_done, on_hash_progress, on_hash_error) {
        var asmCrypto = require("asmcrypto-lite");
        var file_size = file.size;
        var chunk_size = 1 * 1024 * 1024;
        var offset = 0;
        var time_started = Date.now();

        var hasher = new asmCrypto.SHA256();
        var file_reader = new FileReader();

        file_reader.onload = function (e) {
          if (e.target.error === null) {
            offset += e.loaded;

            const uint8_data = new Uint8Array(e.target.result);

            hasher.process(uint8_data);
          } else {
            on_hash_error(e.target.error);
            return;
          }

          var time_elapsed_seconds =
            Math.floor((Date.now() - time_started) / 100) / 10;

          console.log(e.target);

          on_hash_progress(offset / file_size, time_elapsed_seconds);

          if (offset < file_size) {
            file_reader.readAsArrayBuffer(
              file.slice(offset, chunk_size + offset)
            );
          } else {
            hasher.finish();

            on_hash_done(toHexString(hasher.result), time_elapsed_seconds);

            return;
          }
        };
        file_reader.readAsArrayBuffer(file.slice(offset, chunk_size + offset));
      }

      hash_file(
        e.target.files[0],
        on_hash_done,
        on_hash_progress,
        on_hash_error
      );
    },
  },
};
</script>

<style></style>
