<template>
  <input
    type="file"
    style="display: none"
    ref="fileInput"
    accept="*"
    @change="onFilePicked"
  />
  <button
    class="bg-secondary border-2 border-transparent hover:border-primary font-dm_sans rounded-xl text-md text-white px-10 py-2.5 text-center inline-flex items-center"
    @click="onPickFile"
  >
    Upload file
  </button>
  <div class="mt-4">
    <div
      type="button"
      class="bg-primary w-64 h-10 rounded-lg flex items-center justify-center mx-auto"
    >
      <span v-if="this.file_name !== null" class="text-secondary text-2xl">{{
        computed_file
      }}</span>
      <span v-else class="text-gray-500 text-sm"> Selected file </span>
      <button v-if="this.file_name !== null" @click="deleteFile">
        <svg
          aria-hidden="true"
          class="mt-1 ml-2 -mr-1 w-4 h-4"
          fill="#3B3B3B"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "fileImport",
  data() {
    return {
      file_name: this.$store.state.fileName,
    };
  },
  computed: {
    computed_file() {
      var return_value;
      if (this.file_name.length > 12) {
        return_value =
          this.file_name.substring(0, 10) +
          "..." +
          this.file_name.substring(
            this.file_name.length - 4,
            this.file_name.length
          );
      } else {
        return_value = this.file_name;
      }
      return return_value;
    },
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      if (
        /^[A-Za-z0-9_]+\.[A-Za-z0-9]+$/.test(event.target.files[0].name) != true
      ) {
        this.$parent.iswrongname = true;
        this.$refs.fileInput.value = null;
        return;
      }
      this.file_name = event.target.files[0].name;
      this.$parent.$refs.hashfile.onFilePicked(event);
      this.$store.commit("setFileName", this.file_name);
    },
    deleteFile() {
      this.file_name = null;
      this.$refs.fileInput.value = null;
      this.$parent.$refs.hashfile.hash = null;
      this.$store.commit("setHashFile", null);
      this.$store.commit("setFileName", null);
    },
  },
};
</script>
