<template>
  <footer class="p-4 mt-16 md:px-6 md:py-8">
    <div class="sm:flex flex-wrap sm:items-center sm:justify-between">
      <router-link class="flex items-center mb-4 sm:mb-0 pt-1" to="/"
        ><img
          class="aspect-auto xs:h-6 md:shrink-0"
          src="../../public/logo_patent.png"
          alt=""
        />
      </router-link>
      <ul
        class="flex flex-nowrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400 pt-3 pl-1"
      >
        <li>
          <a
            href="#"
            class="font-dm_400 text-[#0c1d37] mr-4 hover:text-secondary md:mr-6"
            >About</a
          >
        </li>
        <li>
          <a
            href="#"
            class="font-dm_400 text-[#0c1d37] mr-4 hover:text-secondary md:mr-6 whitespace-nowrap"
            >Privacy Policy</a
          >
        </li>
        <li>
          <a
            href="#"
            class="font-dm_400 text-[#0c1d37] mr-4 hover:text-secondary md:mr-6"
            >Licensing</a
          >
        </li>
        <li>
          <a href="#" class="font-dm_400 text-[#0c1d37] hover:text-secondary"
            >Contact</a
          >
        </li>
      </ul>
    </div>
    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400"
      >© 2022 <a class="hover:underline">Patentidea</a>. All Rights Reserved.
    </span>
  </footer>
</template>
