import { createStore } from "vuex";

export default createStore({
  state: {
    accountAddr: "",
    fileName: null,
    hashFile: null,
    isConnected: null,
  },
  getters: {},
  mutations: {
    setAddress(state, _accountAddr) {
      state.accountAddr = _accountAddr;
    },
    setFileName(state, _file) {
      state.fileName = _file;
    },
    setHashFile(state, _hash) {
      state.hashFile = _hash;
    },
    setWalletStatus(state, _isConnected) {
      state.isConnected = _isConnected;
    },
  },
  actions: {},
  modules: {},
});
