<template>
  <div class="bg-primary">
    <nav
      class="max-md:relative container px-6 py-6 mx-auto md:flex md:items-center"
    >
      <div
        class="flex items-center justify-between md:flex-1 md:justify-center md:mr-auto"
      >
        <router-link to="/"
          ><img
            class="aspect-auto xs:h-6 md:shrink-0"
            src="../../public/logo_patent.png"
            alt=""
          />
        </router-link>
        <!-- Mobile menu button -->
        <div @click="toggleNav" class="flex md:hidden">
          <button
            type="button"
            class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <div
        class="bg-primary max-md:absolute max-md:inset-x-0 md:flex-1 md:justify-center md:items-center"
      >
        <div
          :class="showMenu ? 'flex' : 'hidden'"
          class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:justify-center md:items-center md:space-x-10 md:mt-0 z-10"
        >
          <router-link
            to="/"
            id="link"
            class="text-gray-800 hover:text-secondary font-dm_sans"
            >Home
          </router-link>
          <router-link
            to="/patent"
            id="link"
            class="text-gray-800 hover:text-secondary font-dm_sans"
            >Patent
          </router-link>
          <router-link
            to="/price"
            id="link"
            class="text-gray-800 hover:text-secondary font-dm_sans"
            >Price
          </router-link>
        </div>
      </div>
      <div class="max-lg:hidden md:flex-1 md:justify-center md:ml-auto">
        <MetaMask />
      </div>
    </nav>
  </div>
</template>
<script>
import MetaMask from "@/components/MetaMask.vue";
import { ref } from "vue";
export default {
  setup() {
    let showMenu = ref(false);
    const toggleNav = () => (showMenu.value = !showMenu.value);
    return { showMenu, toggleNav };
  },
  name: "NavView",
  components: {
    MetaMask,
  },
};
</script>

<style></style>
