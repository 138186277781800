<template>
  <div>
    <div>
      <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35 pt-20">
        OUR
        <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
          >PRICE</span
        >
      </h1>
    </div>
    <div class="flex justify-center gap-16 pt-10">
      <div class="w-72 bg-white rounded-lg p-4">
        <h2 class="font-dm_sans text-2xl text-[#8247e5] pb-2">Unitary</h2>
        <h1 class="font-dm_sans text-6xl inline-block">2</h1>
        <h2 class="font-dm_sans text-2xl inline-block">matic</h2>
        <p class="pt-4">
          In a few clicks, protect your creations anywhere in the world in real
          time!
        </p>
      </div>
      <div class="w-72 bg-white rounded-lg p-4">
        <h2 class="font-dm_sans text-2xl text-secondary">Company</h2>
        <h2 class="font-dm_sans text-2xl py-4">On quotation</h2>
        <p>
          Provide your clients with unlimited access to the Patentidea platform,
          tailored to their intangible assets protection, enhancement and
          defence needs.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
