<template>
  <div class="mt-4">
    <button
      :disabled="!getStatus"
      :class="
        getStatus
          ? 'bg-secondary hover:border-third animate-loop_shadow'
          : 'text-gray-600 hover:border-gray-600 bg-gray-300'
      "
      class="font-dm_sans text-md text-white rounded-xl border-2 border-transparent w-80 py-2.5"
      @click="sendTx"
    >
      <span v-if="getWalletStatus"> Save on chain </span>
      <span v-else>Metamask not connected</span>
    </button>
  </div>
</template>

<script>
const hex = require("string-hex");
export default {
  name: "SendTx",
  data() {
    return {
      file: null,
    };
  },
  computed: {
    getWalletStatus() {
      return this.$store.state.isConnected;
    },
    getHashStatus() {
      return !!this.$store.state.hashFile;
    },
    getStatus() {
      return this.$store.state.isConnected && !!this.$store.state.hashFile;
    },
  },
  methods: {
    sendTx() {
      let txData =
        "Patent registered by Patentidea.web3 on behalf of : " +
        this.$store.state.accountAddr +
        "; Hash of the document :  " +
        this.$parent.$refs.hashfile.hash;
      let txParameter = {
        to: "0xc9edf5B0F7c4d7cA66A45236d14dfC0a50019fAE",
        from: this.$store.state.accountAddr,
        value: "1BC16D674EC80000",
        data: hex(txData),
      };
      if (window.ethereum.networkVersion == 137) {
        if (
          this.$store.state.accountAddr !== "" &&
          this.$store.state.accountAddr.startsWith("0x")
        ) {
          window.ethereum
            .request({ method: "eth_sendTransaction", params: [txParameter] })
            .then((_txhash) => {
              this.$parent.txhash = _txhash;
            });
        }
      } else {
        this.$parent.iswrongnetwork = true;
      }
    },
    switchNetwork() {
      window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1691" }],
      });
      this.$parent.iswrongnetwork = null;
    },
  },
};
</script>

<style></style>
