<template>
  <div>
    <div>
      <h1 class="font-dm_sans text-4xl text-[#0c1d37] xl:mx-35 pt-20">
        ERROR
        <span class="text-secondary bg-third px-3 leading-normal rounded-lg"
          >404</span
        >
      </h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
